import { useEffect, useState } from "react"

export const useIsHydrated = () => {
    const [isHydrated,setIsHydrated] = useState(false);
    useEffect(() => {
        if(!isHydrated) {
            setIsHydrated(true);
        }
        
    },[isHydrated]);

    return isHydrated;
}